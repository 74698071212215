import PropTypes from "prop-types";
import React from "react";
import { Button, Toast } from "react-bootstrap";

class UpdateNotification extends React.Component {
  state = { show: true };

  handleClose = () => {
    const { onClick } = this.props;
    this.setState({ show: false });
    onClick(false);
  };

  render() {
    const { show } = this.state;
    const { onClick } = this.props;
    return (
      <Toast
        style={{
          position: "fixed",
          zIndex: "100",
          bottom: "2.5em",
          right: "0.5em",
          margin: "0",
          padding: "0",
        }}
        className="bg-warning"
        show={show}
        onClose={this.handleClose}
      >
        <Toast.Header>
          <strong className="me-auto">Onsite</strong>
        </Toast.Header>
        <Toast.Body>
          <p>A new version is available.</p>
          <span
            style={{
              fontWeight: 800,
              fontSize: "2em",
              textAlign: "left",
              paddingRight: "0.5em",
            }}
          >
            <Button size="sm" variant="info" onClick={() => onClick(true)}>
              Reload
            </Button>
          </span>
          <span
            style={{
              fontWeight: 800,
              fontSize: "2em",
              textAlign: "right",
              paddingLeft: "0.5em",
            }}
          >
            <Button size="sm" variant="secondary" onClick={this.handleClose}>
              Dismiss
            </Button>
          </span>
        </Toast.Body>
      </Toast>
    );
  }
}

UpdateNotification.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UpdateNotification;
