import React from "react";

class OfflineBanner extends React.Component {
  render() {
    if (this.props.isOnline) return null;
    return (
      <div
        variant="warning"
        className="bg-warning"
        style={{
          height: "2em",
          position: "fixed",
          top: "0px",
          left: "0px",
          right: "0px",
          padding: "0.4em",
          zIndex: 10,
        }}
      >
        <div style={{ width: "100%" }}>
          <strong>
            Currently Offline <i className={`pi pi-exclamation-triangle`} />
          </strong>
        </div>
      </div>
    );
  }
}

export default OfflineBanner;
