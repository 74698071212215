import React from "react";
import {
  extractClaims,
  getAuthToken,
  removeAuthToken,
  setAuthToken,
  validToken,
} from "../auth";
import ApiService from "../xhr_libs";

const initialState = Object.freeze({
  id: null,
  email: "",
  role: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  isOnline: false,
});

export const PROFILE_KEY = "profile";

export const AppContext = React.createContext({ initialState });

export class AppProvider extends React.Component {
  constructor(props) {
    super(props);
    const isOnline = window.navigator.onLine;
    const claims = extractClaims(getAuthToken());
    this.state =
      Object.keys(claims).length > 0
        ? { ...initialState, ...claims, isOnline }
        : { ...initialState, isOnline };
  }

  componentDidMount() {
    window.addEventListener("online", this.setOnline);
    window.addEventListener("offline", this.setOffline);
    this.fetchProfile();
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.setOnline);
    window.removeEventListener("offline", this.setOffline);
  }

  fetchProfile = () => {
    if (!validToken()) return;
    ApiService.get("/profile")
      .then((res) => {
        this.setUser(res.data);
      })
      .catch((e) => {
        // Load from localStorage if available
        const cachedUserData = JSON.parse(localStorage.getItem(PROFILE_KEY));
        if (cachedUserData) this.setUser(cachedUserData);

        // If we failed here then we must be offline
        if (e && e.code === "ERR_NETWORK") this.setOffline();
      });
  };

  logoutUser = (_) => {
    removeAuthToken();
    this.setState(initialState);
  };

  setOnline = () => {
    console.info("Back online");
    this.setState({ isOnline: true });
  };

  setOffline = () => {
    console.warn("Going offline");
    this.setState({ isOnline: false });
  };

  setToken = (newToken) => {
    setAuthToken(newToken);
    const claims = extractClaims(getAuthToken());
    this.setState({ user: { ...claims } }, () => console.debug("User Updated"));
  };

  setUser = (userData, callback) => {
    callback = callback || function () {};
    // Also store to localStorage for offline
    localStorage.setItem(PROFILE_KEY, JSON.stringify(userData));
    this.setState({ ...userData }, (e) => callback(e));
  };

  render() {
    const { setToken, setUser, logoutUser } = this;
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setToken,
          setUser,
          logoutUser,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const AppConsumer = AppContext.Consumer;
