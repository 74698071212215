import { ProgressSpinner } from "primereact/progressspinner";

const DEFAULT_STYLE = {
  width: "15em",
  height: "15em",
  verticalAlign: `sub`,
};

export const LoadingComponent = ({
  className = `m-auto`,
  style = DEFAULT_STYLE,
  strokeWidth = `5`,
}) => {
  return (
    <ProgressSpinner
      className={className}
      style={style}
      strokeWidth={strokeWidth}
      fill="transparent"
      animationDuration=".7s"
    />
  );
};

export default LoadingComponent;
