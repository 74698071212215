import dayjs from "dayjs";
import jwtDecode from "jwt-decode";

export const TOKEN_KEY = "token";

export function validToken() {
  const jwt = getAuthToken();
  if (!jwt) return false;
  const decoded = jwtDecode(jwt);
  return decoded.exp > dayjs().unix();
}

export function extractClaims(token) {
  if (!token) return {};
  try {
    const decoded = jwtDecode(token);
    return {
      id: decoded.sub.id,
      role: decoded.sub.role,
    };
  } catch {
    removeAuthToken();
    return {};
  }
}

export function getAuthToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function removeAuthToken() {
  localStorage.removeItem(TOKEN_KEY);
  return true;
}

export function setAuthToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
  return true;
}
